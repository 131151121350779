import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import { CssBaseline, ThemeProvider } from "@material-ui/core";
import type { AppProps } from "next/app";
import Head from "next/head";
import { IntlProvider } from "react-intl";

import theme from "../theme";

const cache = createCache({ key: "css" });
cache.compat = true;

const App = (props: AppProps): JSX.Element => {
  const { Component } = props;

  return (
    <>
      <Head>
        <title>Timeline</title>
        <meta content="initial-scale=1, width=device-width" name="viewport" />
        <meta content={theme.palette.primary.main} name="theme-color" />
      </Head>
      <CacheProvider value={cache}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <IntlProvider locale="en">
            <Component {...props.pageProps} />
          </IntlProvider>
        </ThemeProvider>
      </CacheProvider>
    </>
  );
};

export default App;
